@font-face {
  font-family: "Whyte";
  src: url("Whyte-Regular.woff") format("woff");
  /* url('CircularStd-Black.woff') format('woff'); */
  /* font-weight: 900; */
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AgencyFB";
  src: url("AgencyFB-Regular.TTF") format("woff");
  /* url('CircularStd-Black.woff') format('woff'); */
  /* font-weight: 900; */
  font-style: normal;
  font-display: swap;
}
