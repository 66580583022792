@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* font-family: 'Montserrat', sans-serif; */
  font-weight: 500;
  background: #ffffff;
}

.box-tag {
  font-family: "AgencyFB" !important;
}

body {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 500px;
  min-height: 100vh;
  background: #ffffff;
  box-shadow: 0 4px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

svg {
  cursor: pointer;
}

.bg-blue{
  /* background-color:#AF195F; */
  background-color:#45276D;
}

.buttonAnyscor {
  background-color: #45276D; /* button background */
  color: white; /* label colour */
  border: 2px solid #0040ff; /* 2px blue line border */
  padding: 20px 20px;
  border-radius: 20px; /* rounded corner style */
}

.buttonLeague {
  background-color: #45276D; /* button background */    
  margin: 3px;
  padding: 20px 20px;
  border-radius: 20px; /* rounded corner style */
}
.buttonLeagueActive {
  background-color: #0040ff; /* button background */    
  margin: 3px;
  padding: 20px 20px;
  border-radius: 20px; /* rounded corner style */
}

.storeLink {
  position: relative;
  display: inline-block;
  width: 240px;
  height: 80px;
  border-radius: 16px;
  overflow: hidden;
  background-color: black;
}
.storeLink > img {
  --width: 100%;
  position: absolute;
  width: var(--width);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}